import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import colors from 'styles/colors';
import media from 'styles/media';
import { useI18n } from 'services/i18n';
import { Container, Row, Col } from 'styles/grid';
import Layout from 'components/layout';
import SEO from 'components/seo';
import NonStretchedImage from 'components/non-stretched-image.js';
import Links from 'components/links.js';
import { SecondaryButton } from 'components/buttons';
import { PrimaryTitle, BlockTitle, BigText, Text, QuoteText } from 'components/texts';
import PluginUserPositionMP4 from 'videos/en/plugin-userposition-en.mp4';
import PluginUserPositionWEBM from 'videos/en/plugin-userposition-en.webm';
import PluginUserPositionPoster from 'videos/en/plugin-userposition-poster-en.png';

const ArticleContainer = styled(Container).attrs(() => ({
  as: 'article',
}))`
  max-width: 820px;
  margin: auto;

  h2,
  h3 {
    margin-top: 60px;
  }

  img {
    margin: 60px auto;
  }
`;

const ResponsiveCenteredCol = styled(Col)`
  text-align: center;
  ${media.md`
    text-align:inherit;
  `}
`;

const SizingChartReadabilityImproved = () => {
  const [t, language] = useI18n();
  const data = useStaticQuery(graphql`
    query {
      outOfChart: file(relativePath: { eq: "blog/outofchart.png" }) {
        childImageSharp {
          fluid(maxWidth: 820, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const markupHelper = {
    context: 'http://schema.org',
    type: 'Article',
    name: t('blog.sizing-chart-readability-improved.title'),
    datePublished: t('blog.sizing-chart-readability-improved.date'),
    image: 'https://fitle.com' + data.outOfChart.childImageSharp.fluid.src,
  };

  return (
    <Layout>
      <SEO
        title={t('blog.sizing-chart-readability-improved.title')}
        description={
          t('blog.sizing-chart-readability-improved.description') +
          t('blog.sizing-chart-readability-improved.descriptionTwo')
        }
        lang={language}
        markupHelper={markupHelper}
      />
      <section>
        <ArticleContainer>
          <Row>
            <ResponsiveCenteredCol style={{ marginBottom: 60 }}>
              <SecondaryButton to="/blog" text={t('blog.index')} $backButton />
            </ResponsiveCenteredCol>
          </Row>
          <Row>
            <Col sm={12} md={12}>
              <PrimaryTitle>{t('blog.sizing-chart-readability-improved.title')}</PrimaryTitle>
              <Text weight="bold" style={{ margin: '20px 0 100px 0' }}>
                {t('blog.sizing-chart-readability-improved.date')}
              </Text>
              <BigText style={{ marginBottom: '60px' }}>
                {t('blog.sizing-chart-readability-improved.description')}
                <span style={{ fontWeight: 'bold' }}>
                  {t('blog.sizing-chart-readability-improved.descriptionTwo')}
                </span>
              </BigText>
              <div
                style={{
                  paddingLeft: '100px',
                  paddingRight: '100px',
                  backgroundColor: colors.lightBlueGrey,
                }}
              >
                <video
                  autoPlay
                  loop
                  width="100%"
                  muted
                  playsInline
                  poster={PluginUserPositionPoster}
                >
                  <source src={PluginUserPositionWEBM} type="video/webm" />
                  <source src={PluginUserPositionMP4} type="video/mp4" />
                </video>
              </div>
              <QuoteText>
                {t('blog.sizing-chart-readability-improved.subtitleVideo')}
                <span style={{ fontWeight: 'bold' }}>
                  {t('blog.sizing-chart-readability-improved.subtitleVideoTwo')}
                </span>
                {t('blog.sizing-chart-readability-improved.subtitleVideoThree')}
              </QuoteText>
              <BlockTitle>{t('blog.sizing-chart-readability-improved.subtitleOne')}</BlockTitle>
              <Text mobileAlign="left">
                {t('blog.sizing-chart-readability-improved.textOne')}
                <span style={{ fontWeight: 'bold' }}>
                  {t('blog.sizing-chart-readability-improved.textTwo')}
                </span>
              </Text>
              <BlockTitle>{t('blog.sizing-chart-readability-improved.subtitleTwo')}</BlockTitle>
              <Text mobileAlign="left">
                {t('blog.sizing-chart-readability-improved.textThree')}
              </Text>
              <NonStretchedImage
                fluid={data.outOfChart.childImageSharp.fluid}
                alt={t(`blog.sizing-chart-readability-improved.imageAlt`)}
                style={{ overflow: 'inherit' }}
              />
              <QuoteText style={{ margin: '80px 0 120px 0' }}>
                {t('blog.sizing-chart-readability-improved.subtitleImage')}
              </QuoteText>
            </Col>
          </Row>
        </ArticleContainer>
      </section>
      <Links withTitle={true} links={['plugin', 'dashboard']} />
    </Layout>
  );
};

export default SizingChartReadabilityImproved;
